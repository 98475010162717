import { rc        } from '../pwa-rc';
import { mkpicture } from '../utils/picture';

function spa_mksection_aboutus () {

    let html
    = '<a name="about" class="anchor"></a>'
    + '<section class="about pb-5">'
    +   '<div class="container">'

    +     '<div class="section-title">'
    +       '<img class="text-center" src="' + rc.aboutus.logo + '">'
    +       '<h2>' + rc.aboutus.title + '</h2>' + rc.aboutus.cite
    +     '</div>'

    +     '<div class="row">'
    +       '<div class="col-lg-6 order-1 order-lg-2">'
    +           mkpicture (rc.aboutus.media, [ 'webf' ], rc.aboutus.label, 'img-fluid')
    +       '</div>'
    +       '<div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">' + rc.aboutus.body + '</div>'
    +     '</div>'

    +   '</div>'
    + '</section';

    return (html);

}

function spa_mksection_call2action () {

    let html
    = '<section id="cta" class="cta">'
    +   '<div class="container">'

    +     '<div class="row">'
    +       '<div class="col-lg-9 text-center text-lg-left">'
    +         '<h3>' + rc.call2act.head + '</h3>' + rc.call2act.body
    +       '</div>'
    +       '<div class="col-lg-3 cta-btn-container text-center">'
    +         '<a class="cta-btn align-middle" href="#">Call To Action</a>'
    +       '</div>'
    +     '</div>'

    +   '</div>'
    + '</section>';

    return (html);

}

function spa_mksection_contact () {

    let html
    = '<a name="contact" class="anchor"></a>'
    + '<section class="contact">'
    +   '<div class="container">'

    +     '<div class="section-title">'
    +       '<img class="text-center" src="zip/jaques/img/logo.png">'
    +       '<h2>Nous contacter</h2>'
    +     '</div>'

    +     '<div class="row">'

    +       '<div class="col-lg-5 d-flex align-items-stretch">'
    +         '<div class="info">'
    +           '<div class="address">'
    +             '<i class="icofont-google-map"></i>'
    +             '<h4>Adresse:</h4>'
    +             '<p>' + rc.config.address + '</p>'
    +           '</div>'

    +           '<div class="email">'
    +             '<i class="icofont-envelope"></i>'
    +             '<h4>Courriel:</h4>'
    +             '<p>' + rc.config.email + '</p>'
    +           '</div>'

    +           '<div class="phone">'
    +             '<i class="icofont-phone"></i>'
    +             '<h4>Téléphone:</h4>'
    +             '<p><a href="tel:' + rc.config.phone + '">' + rc.config.phone + '</a></p>'
    +           '</div>'

    +           '<iframe src="' + rc.config.mapurl + '" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>'
    +         '</div>'

    +       '</div>'

    +       '<div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">'
    +       '<a name="contactus" class="anchor"></a>'
    +         '<form action="forms/contact.php" method="post" role="form" class="php-email-form">'
    +           '<div class="row">'
    +             '<div class="form-group col-md-6">'
    +               '<label for="name">Nom</label>'
    +               '<input type="text" name="name" class="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />'
    +               '<div class="validate"></div>'
    +             '</div>'
    +             '<div class="form-group col-md-6 mt-3 mt-md-0">'
    +               '<label for="name">Courriel</label>'
    +               '<input type="email" class="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />'
    +               '<div class="validate"></div>'
    +             '</div>'
    +           '</div>'
    +           '<div class="form-group mt-3">'
    +             '<label for="name">Sujet</label>'
    +             '<input type="text" class="form-control" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />'
    +             '<div class="validate"></div>'
    +           '</div>'
    +           '<div class="form-group mt-3">'
    +             '<label for="name">Message</label>'
    +             '<textarea class="form-control" name="message" rows="10" data-rule="required" data-msg="Please write something for us"></textarea>'
    +             '<div class="validate"></div>'
    +           '</div>'
    +           '<div class="mb-3">'
    +             '<div class="loading">Loading</div>'
    +             '<div class="error-message"></div>'
    +             '<div class="sent-message">Your message has been sent. Thank you!</div>'
    +           '</div>'
    +           '<div class="text-center"><button type="submit">Envoyer</button></div>'
    +         '</form>'
    +       '</div>'

    +     '</div>'

    +   '</div>'
    + '</section>';

    return (html);

}

function spa_mksection_featured () {

    let html
    = '<section id="featured-services" class="featured-services section-bg">'
    +   '<div class="container">'

    +     '<div class="row no-gutters">'
    +       '<div class="col-lg-4 col-md-6">'
    +         '<div class="icon-box">'
    +           '<div class="icon"><i class="icofont-computer"></i></div>'
    +           '<h4 class="title"><a href="">Lorem Ipsum</a></h4>'
    +           '<p class="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>'
    +         '</div>'
    +       '</div>'
    +       '<div class="col-lg-4 col-md-6">'
    +         '<div class="icon-box">'
    +           '<div class="icon"><i class="icofont-image"></i></div>'
    +           '<h4 class="title"><a href="">Dolor Sitema</a></h4>'
    +           '<p class="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>'
    +         '</div>'
    +       '</div>'
    +       '<div class="col-lg-4 col-md-6">'
    +         '<div class="icon-box">'
    +           '<div class="icon"><i class="icofont-tasks-alt"></i></div>'
    +           '<h4 class="title"><a href="">Sed ut perspiciatis</a></h4>'
    +           '<p class="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur trade stravi</p>'
    +         '</div>'
    +       '</div>'
    +     '</div>'

    +   '</div>'
    + '</section>'

    return (html);

}

function spa_mksection_jumbotron () {

    let slides = ''; for (let i = 0; i < rc.jumbotron.length; ++ i) {
        slides
        += '<div class="carousel-item active" style="background-image: url(' + rc.jumbotron[i].media + ')">'
        +    '<div class="carousel-container">'
        +      '<div class="container">'
        +        '<h2 class="animate__animated animate__fadeInDown">' + rc.jumbotron[i].head + '</h2>'
        +        '<p class="animate__animated animate__fadeInUp">' + rc.jumbotron[i].desc + '</p>'
        +        '<a href="#contact" class="btn-get-started animate__animated animate__fadeInUp scrollto">En savoir plus</a>'
        +      '</div>'
        +    '</div>'
        +  '</div>';

    }


    let html
    = '<section id="hero">'
    +   '<div id="jumbotron" data-bs-interval="5000" class="carousel slide carousel-fade" data-bs-ride="carousel">'
    +     '<ol class="carousel-indicators" id="jumbotron-indicators"></ol>'
    +     '<div class="carousel-inner" role="listbox">' + slides + '</div>'
    +     '<a class="carousel-control-prev" href="#jumbotron" role="button" data-bs-slide="prev">'
    +       '<span class="carousel-control-prev-icon icofont-simple-left" aria-hidden="true"></span>'
    +       '<span class="sr-only">Previous</span>'
    +     '</a>'
    +     '<a class="carousel-control-next" href="#jumbotron" role="button" data-bs-slide="next">'
    +       '<span class="carousel-control-next-icon icofont-simple-right" aria-hidden="true"></span>'
    +       '<span class="sr-only">Next</span>'
    +     '</a>'
    +   '</div>'
    + '</section>';

    return (html);

}

function spa_mksection_ourclients () {

    let html
    = '<section id="clients" class="clients">'
    +   '<div class="container">'

    +     '<div class="section-title">'
    +       '<h2>Our Clients</h2>'
    +       '<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>'
    +     '</div>'

    +     '<div class="owl-carousel clients-carousel">'
    +       '<img src="zip/template/green/img/clients/client-1.png" alt="">'
    +       '<img src="zip/template/green/img/clients/client-2.png" alt="">'
    +       '<img src="zip/template/green/img/clients/client-3.png" alt="">'
    +       '<img src="zip/template/green/img/clients/client-4.png" alt="">'
    +       '<img src="zip/template/green/img/clients/client-5.png" alt="">'
    +       '<img src="zip/template/green/img/clients/client-6.png" alt="">'
    +       '<img src="zip/template/green/img/clients/client-7.png" alt="">'
    +       '<img src="zip/template/green/img/clients/client-8.png" alt="">'
    +     '</div>'

    +   '</div>'
    + '</section>';

    return (html);

}

function spa_mksection_portfolio () {

    let filters = ''; for (let i = 0; i < rc.folios.length; ++ i) {
        filters += '<li data-filter=".' + rc.folios[i].name + '">' + rc.folios[i].head + '</li>';
    }

    let folios = ''; for (let i = 0; i < rc.portfolio.length; ++ i) {

        if (rc.portfolio[i].hide === true) continue;

        folios
        += '<div class="col-lg-4 col-md-6 portfolio-item ' + rc.portfolio[i].tags.join (' ') + '">'
        +    '<div class="portfolio-wrap">'
        +      '<img src="zip/jaques/img/portfolio/' + rc.portfolio[i].media + '" class="img-fluid" alt="">'
        +      '<div class="portfolio-info">'
        +        '<h4>' + rc.portfolio[i].head + '</h4>'
//      +        '<p>App</p>'
        +        '<div class="portfolio-links">'
        +          '<a href="zip/jaques/img/portfolio/' + rc.portfolio[i].media + '" data-gall="portfolioGallery" class="venobox"'
        +          ' title="' + rc.portfolio[i].head + '"><i class="bx bx-plus"></i></a>'
        +          '<a href="#" title="En savoir plus..."><i class="bx bx-link"></i></a>'
        +        '</div>'
        +      '</div>'
        +    '</div>'
        +  '</div>';

    }

    let html
    = '<a name="portfolio" class="anchor"></a>'
    + '<section class="portfolio">'
    +   '<div class="container">'

    +     '<div class="section-title">'
    +       '<img class="text-center" src="zip/jaques/img/logo.png">'
    +       '<h2>Nos réalisations</h2>'
//  +       '<p></p>'
    +     '</div>'

    +     '<div class="row">'
    +       '<div class="col-lg-12 d-flex justify-content-center">'
    +         '<ul id="portfolio-flters">'
    +           '<li data-filter="*" class="filter-active">Tout</li>'
    +           filters
    +         '</ul>'
    +       '</div>'
    +     '</div>'

    +     '<div class="row portfolio-container">' + folios + '</div>'

    +   '</div>'
    + '</section>';

    return (html);

}

function spa_mksection_services () {

    let maint = ''; for (let i = 0; i < rc.services.length; i ++) {
        maint
        += '<div class="col-lg-4 col-md-6 align-items-stretch" data-aos="zoom-in"'
        +    ' data-aos-delay="' + ((i % 4) * 100) + '">'
        +    '<div class="icon-box iconbox-blue">'
        +      '<div class="icon">' + rc.services[i].icon + '<i class="bx bxl-dribbble"></i></div>'
        +      '<h4><a href="">' + rc.services[i].head + '</a></h4>'
        +      '<p>' + rc.services[i].desc + '</p>'
        +    '</div>'
        +  '</div>';
    }

    let html
    = '<a name="activities" class="anchor"></a>'
    + '<section class="services">'
    +   '<div class="container">'

    +     '<div class="section-title">'
    +       '<img class="text-center" src="zip/jaques/img/logo.png">'
    +       '<h2>Nos activités</h2>'
    +     '</div>'

    +     '<div class="row">' + maint + '</div>'

    +   '</div>'
    + '</section>';

    return (html);

}

function spa_mksection_team () {

    let html
    = '<section id="team" class="team section-bg">'
    +   '<div class="container">'

    +     '<div class="section-title">'
    +       '<h2>Team</h2>'
    +       '<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>'
    +     '</div>'

    +     '<div class="row">'
    +       '<div class="col-lg-4 col-md-6 d-flex align-items-stretch">'
    +         '<div class="member">'
    +           '<img src="zip/template/green/img/team/team-1.jpg" alt="">'
    +           '<h4>Walter White</h4>'
    +           '<span>Chief Executive Officer</span>'
    +           '<p>'
    +             'Magni qui quod omnis unde et eos fuga et exercitationem. Odio veritatis perspiciatis quaerat qui aut aut aut'
    +           '</p>'
    +           '<div class="social">'
    +             '<a href=""><i class="icofont-twitter"></i></a>'
    +             '<a href=""><i class="icofont-facebook"></i></a>'
    +             '<a href=""><i class="icofont-instagram"></i></a>'
    +             '<a href=""><i class="icofont-linkedin"></i></a>'
    +           '</div>'
    +         '</div>'
    +       '</div>'

    +       '<div class="col-lg-4 col-md-6 d-flex align-items-stretch">'
    +         '<div class="member">'
    +           '<img src="zip/template/green/img/team/team-2.jpg" alt="">'
    +           '<h4>Sarah Jhinson</h4>'
    +           '<span>Product Manager</span>'
    +           '<p>'
    +             'Repellat fugiat adipisci nemo illum nesciunt voluptas repellendus. In architecto rerum rerum temporibus'
    +           '</p>'
    +           '<div class="social">'
    +             '<a href=""><i class="icofont-twitter"></i></a>'
    +             '<a href=""><i class="icofont-facebook"></i></a>'
    +             '<a href=""><i class="icofont-instagram"></i></a>'
    +             '<a href=""><i class="icofont-linkedin"></i></a>'
    +           '</div>'
    +         '</div>'
    +       '</div>'

    +       '<div class="col-lg-4 col-md-6 d-flex align-items-stretch">'
    +         '<div class="member">'
    +           '<img src="zip/template/green/img/team/team-3.jpg" alt="">'
    +           '<h4>William Anderson</h4>'
    +           '<span>CTO</span>'
    +           '<p>'
    +             'Voluptas necessitatibus occaecati quia. Earum totam consequuntur qui porro et laborum toro des clara'
    +           '</p>'
    +           '<div class="social">'
    +             '<a href=""><i class="icofont-twitter"></i></a>'
    +             '<a href=""><i class="icofont-facebook"></i></a>'
    +             '<a href=""><i class="icofont-instagram"></i></a>'
    +             '<a href=""><i class="icofont-linkedin"></i></a>'
    +           '</div>'
    +         '</div>'
    +       '</div>'

    +     '</div>'

    +   '</div>'
    + '</section>';

    return (html);

}

function spa_mksection_whyus () {

    let html
    = '<section id="why-us" class="why-us">'
    +   '<div class="container">'

    +     '<div class="row no-gutters">'

    +       '<div class="col-lg-4 col-md-6 content-item">'
    +         '<span>01</span>'
    +         '<h4>Lorem Ipsum</h4>'
    +         '<p>Ulamco laboris nisi ut aliquip ex ea commodo consequat. Et consectetur ducimus vero placeat</p>'
    +       '</div>'

    +       '<div class="col-lg-4 col-md-6 content-item">'
    +         '<span>02</span>'
    +         '<h4>Repellat Nihil</h4>'
    +         '<p>Dolorem est fugiat occaecati voluptate velit esse. Dicta veritatis dolor quod et vel dire leno para dest</p>'
    +       '</div>'

    +       '<div class="col-lg-4 col-md-6 content-item">'
    +         '<span>03</span>'
    +         '<h4> Ad ad velit qui</h4>'
    +         '<p>Molestiae officiis omnis illo asperiores. Aut doloribus vitae sunt debitis quo vel nam quis</p>'
    +       '</div>'

    +       '<div class="col-lg-4 col-md-6 content-item">'
    +         '<span>04</span>'
    +         '<h4>Repellendus molestiae</h4>'
    +         '<p>Inventore quo sint a sint rerum. Distinctio blanditiis deserunt quod soluta quod nam mider lando casa</p>'
    +       '</div>'

    +       '<div class="col-lg-4 col-md-6 content-item">'
    +         '<span>05</span>'
    +         '<h4>Sapiente Magnam</h4>'
    +         '<p>Vitae dolorem in deleniti ipsum omnis tempore voluptatem. Qui possimus est repellendus est quibusdam</p>'
    +       '</div>'

    +       '<div class="col-lg-4 col-md-6 content-item">'
    +         '<span>06</span>'
    +         '<h4>Facilis Impedit</h4>'
    +         '<p>Quis eum numquam veniam ea voluptatibus voluptas. Excepturi aut nostrum repudiandae voluptatibus corporis sequi</p>'
    +       '</div>'

    +     '</div>'

    +   '</div>'
    + '</section>'

    return (html);

}

export function spa_mkbody () {
    let html = '', hero = '';

    for (var i = 0; i < rc.sections.length; ++ i) {

        // Collect the next SPA section defined in our configuration data and determine if it should be processed
        if (rc.sections[i].hide) continue; let name = rc.sections[i].name;

        // Call the appropriate constructor for this section (needed for Rollup to detect function to import)
        switch (name) {
            case 'aboutus'     : html += spa_mksection_aboutus     (); break;
            case 'call2action' : html += spa_mksection_call2action (); break;
            case 'contact'     : html += spa_mksection_contact     (); break;
            case 'featured'    : html += spa_mksection_featured    (); break;
            case 'jumbotron'   : hero += spa_mksection_jumbotron   (); break;
            case 'ourclients'  : html += spa_mksection_ourclients  (); break;
            case 'portfolio'   : html += spa_mksection_portfolio   (); break;
            case 'services'    : html += spa_mksection_services    (); break;
            case 'team'        : html += spa_mksection_team        (); break;
            case 'whyus'       : html += spa_mksection_whyus       (); break;
        }

    }

    return (hero + '<main id="main" class="mt-5">' + html + '</main>');

}

// vim: fdm=marker fmr={,}
