import { rc } from '../pwa-rc';

export function spa_mkhead () {

    let html = icons = '';
    let phone = rc.config.phone;
    let email = rc.config.email;

    if (true) html
    += '<div id="topbar" class="d-none d-lg-flex align-items-center fixed-top">'
    +    '<div class="container d-flex justify-content-between">'
    +      '<div class="contact-info">'
    +        '<i class="icofont-envelope"></i> <a href="mailto:' + email + '">' + email + '</a>'
    +        '<i class="icofont-phone"></i> <a href="tel:' + phone + '">' + phone + '</a>'
    +      '</div>'
//  +      '<div class="social-links">'
//  +        '<a href="#" class="twitter"><i class="icofont-twitter"></i></a>'
//  +        '<a href="#" class="facebook"><i class="icofont-facebook"></i></a>'
//  +        '<a href="#" class="instagram"><i class="icofont-instagram"></i></a>'
//  +        '<a href="#" class="skype"><i class="icofont-skype"></i></a>'
//  +        '<a href="#" class="linkedin"><i class="icofont-linkedin"></i></i></a>'
//  +      '</div>'
    +    '</div>'
    +  '</div>';

    html
    += '<header id="header" class="fixed-top">'
    +    '<div class="container d-flex align-items-center">'

    +      '<h1 class="logo me-auto">'
    +        '<a href="index.html" class="logo me-auto">'
    +        '<img src="zip/jaques/img/logo-square.png" alt="Sylvain Jaques — Paysagiste" class="img-fluid">' + 'aques'
//  +        '<img width="200px" height="150" src="zip/jaques/img/logo.png" alt="Sylvain Jaques — Paysagiste" class="img-fluid">'
    +        '</a>'
    +      '</h1>'

    +      '<nav class="nav-menu d-none d-lg-block">'
    +        '<ul>'
    +          '<li class="active"><a href="index.html">Accueil</a></li>'
    +          '<li><a href="#about">Notre entreprise</a></li>'
    +          '<li><a href="#activities">Nos activités</a></li>'
    +          '<li><a href="#portfolio">Nos réalisations</a></li>'
    +          '<li><a href="#contact">Nos coordonnées</a></li>'

    +        '</ul>'
    +      '</nav>'

    +      '<a href="#contactus" class="get-started-btn scrollto">Nous contacter</a>'

    +    '</div>'
    +  '</header>';

    return (html);

}

// vim: fdm=marker fmr={,}
