import { rc } from '../pwa-rc';

export function spa_mktail () {
    let icons = '';

    if (rc.config.footer.icons) {
        icons = '<div class="social-links">';
        for (let i = 0; i < rc.config.socialise.length; ++ i) {
            let name = rc.config.socialise[i].name, link = rc.config.socialise[i].link

            icons += '<a href="' + (typeof (link) != undefined ? link : '#') + '" class="' + name +'">'
            + '<i class="bx bxl-' + name + '"></i></a>';
        }
        icons += '</div>';
    }

    let html
    = '<footer id="footer">'
    +   '<div class="container">'   + rc.config.footer.text + icons
    +     '<div class="copyright">' + rc.config.copyright   + '</div>'
    +     '<div class="credits">'   + rc.config.credits     + '</div>'
    +   '</div>'
    + '</footer>'
    + '<a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>'

    return (html);

}

// vim: fdm=marker fmr={,}
