
export function mksrcset (tag, filename, extension, label, classes, attributes) {

    let basename = filename .substr (0, filename .lastIndexOf ('-'));
    let mime = extension === 'svg' ? 'text/xml+svg' : 'image/' + extension;

    return '<' + tag + (tag === 'source' ? ' type="' + mime + '"' : '') + ' srcset="'
    + basename + '-xs.' + extension +  ' 576w, '
    + basename + '-sm.' + extension +  ' 768w, '
    + basename + '-md.' + extension +  ' 996w, '
    + basename + '-lg.' + extension + ' 1200w, '
    + basename + '-xl.' + extension + ' 1920w, '
    + basename + '-hr.' + extension + '" '
    + 'alt="' + label + '" class="' + classes + '" ' + attributes + '>';

}
