export let rc = {
  "aboutus": {
    "body": "<p class=\"font-italic\">\n  Ayant travaillé durant quinze ans au sein de Geiser Paysagiste comme employé qualifié responsable du secteur\n   entretien, Monsieur Jaques a eu l'opportunité de reprendre ce secteur, et par ce biais, se mettre à son\n   compte.\n</p>\n<ul>\n  <li><i class=\"icofont-check-circled\"></i> Nous consacrons l'essentiel de notre temps à l'entretien de jardins,\n     tout en exécutant en parallèle de petites créations.</li>\n  <li><i class=\"icofont-check-circled\"></i> Pour des mandats de grande envergure, Monsieur Jaques travaille en\n     collaboration avec Monsieur Geiser.</li>\n  <li><i class=\"icofont-check-circled\"></i> Nous employons actuellement quatre personnes qualifiées et un apprenti.</li>\n  <li><i class=\"icofont-check-circled\"></i> Jaques Paysagiste est une entreprise formatrice.</li>\n</ul>\n<p>L'entreprise Jaques Paysagiste a été créé en janvier 2007.</p>",
    "cite": "<p>Tous les hommes naissent égaux... mais les meilleurs sont jardiniers.</p>",
    "label": "S. Jaques, Paysagiste, trente années d'expérience, entreprise formatrice, quatre personnes qualifiées, un apprenti; Nous consacrons l'essentiel de notre temps à l'entretien de jardins,. tout en exécutant en parallèle de petites créations",
    "logo": "zip/jaques/img/logo.png",
    "media": "zip/jaques/img/about-hr.jpg",
    "title": "Bienvenue<br><span>sur le site de S. Jaques Paysagiste</span>"
  },
  "call2act": {
    "body": "<p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur\nsint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
    "head": "Call to action"
  },
  "config": {
    "address": "Route du Seyon 19, 2056 Dombresson",
    "copyright": "&copy; Copyright <em>Sylvain</em> <strong>Jaques</strong>. Tous droits réservés.",
    "credits": "Fait en Suisse par <a href=\"https://saminfo.ch/\">SAMinfo</a>",
    "email": "info@jaquespaysagiste.ch",
    "footer": {
      "icons": false,
      "text": "<h3>Sylvain Jaques<br><small style=\"font-weight: 300\">Paysagiste</small></h3>\n<p>Un professionnel, c'est plus naturel&nbsp;!</p>"
    },
    "mapurl": "https://bit.ly/2MPnbQ7",
    "phone": "+41 79 436 8323",
    "socialise": [
      {
        "name": "twitter"
      },
      {
        "name": "facebook"
      },
      {
        "name": "instagram"
      },
      {
        "name": "google-plus"
      },
      {
        "name": "linkedin"
      }
    ]
  },
  "folios": [
    {
      "head": "Entretien",
      "name": "entretien",
      "para": "Tailles d'arbustes et d'arbres, haies, talus et élagages. Entretien des plates-bandes, des rosiers ainsi que divers plantations"
    },
    {
      "head": "Mur & escalier",
      "name": "murs",
      "para": "Pose de murs et d'escaliers, selon le terrain et la demande."
    },
    {
      "head": "Palissade & bois",
      "name": "bois",
      "para": "Pose de palissades en bois ainsi que de pergolas et autres articles en bois."
    },
    {
      "head": "Créations",
      "name": "création",
      "para": "Créations de divers pavages et dallages. Gazon et étang."
    }
  ],
  "jumbotron": [
    {
      "desc": "De façon générale, il est impératif de tailler hors gel. Au début de l'hiver, il est parfois encore possible d'éclaircir les plantes les plus vigoureuses, comme les grimpantes, les rosiers et des vivaces, qui sont restées encore belles au début de l'automne.",
      "head": "Taillez (ou pas) en hiver",
      "media": "zip/template/green/img/green-bg-07.jpg"
    },
    {
      "desc": "Les semis peuvent commencer à être mis en œuvre sous abris. S'il ne gèle pas en janvier, vous pouvez même planter quelques rosiers, arbres et arbustes caducs, ainsi que certains fruitiers et vivaces (sauf les annuelles), et en profiter pour bêcher un peu la terre alentours afin de l'aérer. Enfin, commencez à préparer les bulbes d'été.",
      "head": "Plantez, semez, dégustez !",
      "media": "zip/template/green/img/green-bg-23.jpg"
    },
    {
      "desc": "Pour profiter à 100 % du jardin quand la nature se réveillera, il est bon de réaliser les gros travaux d'aménagement en hiver, toujours si le temps le permet",
      "head": "Objectif printemps",
      "media": "zip/template/green/img/green-bg-28.jpg"
    }
  ],
  "package": {
    "appname": "pwa",
    "author": "JM Marcastel <don@isle.plus>",
    "bugs": {
      "url": "https://github.com/PATinfo/pat-jaques-paysagiste/issues"
    },
    "contributors": [
      "ISLE Suisse SA",
      "SAMinfo <saminfo.ch>"
    ],
    "dependencies": {
      "@popperjs/core": "^2.6.0",
      "bootstrap": "^4.6.0",
      "isotope-layout": "^3.0.6",
      "jquery": "^3.5.1",
      "jquery-easing": "0.0.1",
      "owl.carousel": "^2.3.4",
      "venobox": "^1.9.2"
    },
    "description": "Sylvain Jaques, Paysagiste -- Site institutionel",
    "devDependencies": {
      "@babel/cli": "^7.12.8",
      "@babel/core": "^7.12.9",
      "@babel/preset-env": "^7.12.7",
      "@popperjs/core": "^2.5.4",
      "@rollup/plugin-babel": "^5.2.2",
      "@rollup/plugin-commonjs": "^17.0.0",
      "@rollup/plugin-json": "^4.1.0",
      "@rollup/plugin-node-resolve": "^11.0.0",
      "@rollup/plugin-replace": "^2.3.4",
      "@typescript-eslint/eslint-plugin": "^4.14.2",
      "@typescript-eslint/parser": "^4.14.2",
      "autoprefixer": "^10.0.4",
      "bundlewatch": "^0.3.1",
      "clean-css-cli": "^4.3.0",
      "cross-env": "^7.0.3",
      "eslint": "^7.14.0",
      "eslint-config-xo": "^0.33.1",
      "eslint-plugin-import": "^2.22.1",
      "eslint-plugin-unicorn": "^23.0.0",
      "find-unused-sass-variables": "^3.0.0",
      "glob": "^7.1.6",
      "hammer-simulator": "0.0.1",
      "hugo-bin": "^0.67.0",
      "ip": "^1.1.5",
      "jquery": "^3.5.1",
      "karma": "^5.2.3",
      "karma-browserstack-launcher": "1.4.0",
      "karma-chrome-launcher": "^3.1.0",
      "karma-coverage-istanbul-reporter": "^3.0.3",
      "karma-detect-browsers": "^2.3.3",
      "karma-firefox-launcher": "^2.1.0",
      "karma-jasmine": "^4.0.1",
      "karma-jasmine-html-reporter": "^1.5.4",
      "karma-rollup-preprocessor": "^7.0.5",
      "linkinator": "^2.5.1",
      "lockfile-lint": "^4.3.7",
      "nodemon": "^2.0.6",
      "postcss": "^8.1.10",
      "postcss-cli": "^8.3.0",
      "rollup": "^2.38.1",
      "rollup-plugin-css-only": "^3.1.0",
      "rollup-plugin-istanbul": "^2.0.1",
      "rollup-plugin-terser": "^7.0.2",
      "rtlcss": "^2.6.2",
      "sass": "^1.29.0",
      "stylelint": "^13.8.0",
      "stylelint-config-twbs-bootstrap": "^2.1.0",
      "terser": "5.1.0",
      "vnu-jar": "20.6.30"
    },
    "homepage": "https://jaquespaysagiste.ch",
    "keywords": [
      "paysagiste",
      "arboriculteur",
      "arboriste",
      "boy",
      "fleuriste",
      "horticulteur",
      "jardiniste",
      "légumiste",
      "maraîcher",
      "pépiniériste"
    ],
    "license": "UNLICENSED",
    "name": "jaques",
    "peerDependencies": {
      "@popperjs/core": "^2.5.4"
    },
    "private": "true",
    "repository": {
      "type": "git",
      "url": "git@marcastel.github:PATinfo/pat-jaques-paysagiste.git"
    },
    "version": "1.0.0"
  },
  "portfolio": [
    {
      "head": "Pergola lors d'une exposition",
      "media": "creations-01.jpg",
      "tags": [
        "bois",
        "pergola"
      ]
    },
    {
      "head": "Détails de la pergola",
      "media": "palissade-10.jpg",
      "tags": [
        "bois",
        "pergola"
      ]
    },
    {
      "head": "Création d'un étang pour une exposition",
      "media": "creations-03.jpg",
      "tags": [
        "création",
        "etang"
      ]
    },
    {
      "head": "Création d'un gazon - avant",
      "media": "entretien-33.jpg",
      "tags": [
        "création",
        "gazon"
      ]
    },
    {
      "head": "Création d'un gazon - après",
      "media": "entretien-38.jpg",
      "tags": [
        "création",
        "gazon"
      ]
    },
    {
      "head": "Montage d'un mur pierre sèche, d'un mur en pierre et pose d'escaliers",
      "media": "img-202101-001-lg.jpg",
      "tags": [
        "murs"
      ]
    },
    {
      "head": "Montage d'un mur pierre sèche",
      "media": "img-202101-002-lg.jpg",
      "tags": [
        "murs"
      ]
    },
    {
      "head": "Pose d'une palissade en bois sur un mur en pierre sèche",
      "media": "img-202101-003-lg.jpg",
      "tags": [
        "bois"
      ]
    },
    {
      "head": "Pose d'un chemin d'accès en pavé de type béton",
      "media": "img-202101-004-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Pose d'un chemin d'accès avec escalier en pavé de type béton",
      "media": "img-202101-005-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Pose d'un chemin d'accès avec escalier en pavé de type béton",
      "media": "img-202101-006-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Pose d'une rampe d'escalier en pierre de roche avec murs",
      "media": "img-202101-007-lg.jpg",
      "tags": [
        "murs"
      ]
    },
    {
      "head": "Taille d'un Saule pleureur",
      "media": "img-202101-008-lg.jpg",
      "tags": [
        "entretien"
      ]
    },
    {
      "head": "Pose d'une terrasse en pavé de type béton avec une rosace de couleur différente",
      "media": "img-202101-009-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Pose d'une terrasse en pavé de type béton avec l'accès en marches d'escalier en pierre de roche",
      "media": "img-202101-010-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Taille d'une haie de divers arbustes",
      "media": "img-202101-011-lg.jpg",
      "tags": [
        "entretien"
      ]
    },
    {
      "head": "Élagage d'un peuplier avec nacelle",
      "media": "img-202101-012-lg.jpg",
      "tags": [
        "entretien"
      ]
    },
    {
      "head": "Pose d'un mur de soutien pour la pose d'une terrasse",
      "media": "img-202101-013-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Pose de dalles pour une terrasse",
      "media": "img-202101-014-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Pose de ballast dans un talus pour faciliter l'entretien",
      "media": "img-202101-015-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Détail de la pose de ballast dans un talus pour faciliter l'entretien",
      "media": "img-202101-016-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Préparation de la surface en terre pour la pose du gazon pré-cultivé",
      "media": "img-202101-018-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Préparation de la surface en terre pour la pose du gazon pré-cultivé avec diverses plantations",
      "media": "img-202101-019-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Mise en place de gazon pré-cultivé",
      "media": "img-202101-017-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Mise en place de gazon pré-cultivé",
      "media": "img-202101-020-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Résultat après la pose du gazon pré-cultivé et des plantations",
      "media": "img-202101-021-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Résultat après la pose du gazon pré-cultivé et des plantations",
      "media": "img-202101-022-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Résultat après la pose du gazon pré-cultivé et des plantations",
      "media": "img-202101-023-lg.jpg",
      "tags": [
        "création"
      ]
    },
    {
      "head": "Taille de talus de cotoneaster",
      "hide": true,
      "media": "entretien-18.jpg",
      "tags": [
        "entretien"
      ]
    },
    {
      "head": "Cotoneaster",
      "hide": true,
      "media": "entretien-17.jpg",
      "tags": [
        "entretien"
      ]
    },
    {
      "head": "Taille de talus",
      "hide": true,
      "media": "entretien-19.jpg",
      "tags": [
        "entretien"
      ]
    },
    {
      "head": "Taille de talus",
      "hide": true,
      "media": "entretien-34.jpg",
      "tags": [
        "entretien",
        "talus"
      ]
    },
    {
      "head": "Tailles des arbustes et buissons",
      "hide": true,
      "media": "entretien-36.jpg",
      "tags": [
        "entretien",
        "arbustes"
      ]
    },
    {
      "head": "Taille d un bosquet d'arbustes (avant)",
      "hide": true,
      "media": "taille-01.jpg",
      "tags": [
        "entretien",
        "taille",
        "arbustes"
      ]
    },
    {
      "head": "Taille d un bosquet d'arbustes (après)",
      "hide": true,
      "media": "taille-02.jpg",
      "tags": [
        "entretien",
        "taille",
        "arbustes"
      ]
    },
    {
      "head": "Taille d un arbre fruitier",
      "hide": true,
      "media": "taille-03.jpg",
      "tags": [
        "entretien",
        "taille",
        "fruitier"
      ]
    },
    {
      "head": "Muret soutenant une palissade",
      "hide": true,
      "media": "escalier-21.jpg",
      "tags": [
        "murs"
      ]
    },
    {
      "head": "Détails du muret",
      "hide": true,
      "media": "escalier-23.jpg",
      "tags": [
        "murs"
      ]
    },
    {
      "head": "Création d'un escalier",
      "hide": true,
      "media": "escalier-22.jpg",
      "tags": [
        "murs"
      ]
    },
    {
      "head": "Détails de l'escalier",
      "hide": true,
      "media": "escalier-20.jpg",
      "tags": [
        "murs"
      ]
    },
    {
      "head": "Escalier depuis le haut",
      "hide": true,
      "media": "escalier-25.jpg",
      "tags": [
        "murs"
      ]
    },
    {
      "head": "Escalier Escalier d'entrée en marches pleines et murets en éléments-blocs",
      "hide": true,
      "media": "escalier-entree-02.jpg",
      "tags": [
        "murs"
      ]
    },
    {
      "head": "Détail du muret posé en léger arrondi",
      "hide": true,
      "media": "escalier-entree-04.jpg",
      "tags": [
        "murs",
        "muret"
      ]
    },
    {
      "head": "Pose d'une palissade en Mélèze",
      "hide": true,
      "media": "palissade-30.jpg",
      "tags": [
        "bois",
        "palissade"
      ]
    },
    {
      "head": "Vue de la palissade en dénivelé",
      "hide": true,
      "media": "palissade-31.jpg",
      "tags": [
        "bois",
        "palissade"
      ]
    },
    {
      "head": "Angle de la maison avec une porte d'entrée",
      "hide": true,
      "media": "palissade-28.jpg",
      "tags": [
        "bois",
        "palissade"
      ]
    },
    {
      "head": "Montage d un cabanon(différents modèles possible)",
      "hide": true,
      "media": "bois-01.jpg",
      "tags": [
        "bois",
        "cabanon"
      ]
    },
    {
      "head": "Dallage de granite de Chine",
      "hide": true,
      "media": "dallage-sylvain-jaques.jpg",
      "tags": [
        "création",
        "dallages"
      ]
    },
    {
      "head": "Granite de Chine",
      "hide": true,
      "media": "sylvain-jaques-dallages-02.jpg",
      "tags": [
        "création",
        "dallages"
      ]
    },
    {
      "head": "Terrasse en pavés",
      "hide": true,
      "media": "pavage-26-2.jpg",
      "tags": [
        "création",
        "pavage"
      ]
    },
    {
      "head": "Terrasse en pavés",
      "hide": true,
      "media": "pavage-27.jpg",
      "tags": [
        "création",
        "pavage"
      ]
    },
    {
      "head": "Pavage de divers coloris et grandeurs",
      "hide": true,
      "media": "pavage-28.jpg",
      "tags": [
        "création",
        "pavage"
      ]
    },
    {
      "head": "Détails du pavage",
      "hide": true,
      "media": "pavage-29.jpg",
      "tags": [
        "création",
        "pavage"
      ]
    },
    {
      "head": "Rocaille avec ballasts et différentes variétés de plantes",
      "hide": true,
      "media": "creations-02.jpg",
      "tags": [
        "création",
        "rocailles"
      ]
    },
    {
      "head": "Terrasse en dalles de 50 x 50 cm",
      "hide": true,
      "media": "dallage-14.jpg",
      "tags": [
        "création",
        "dallage"
      ]
    },
    {
      "head": "Création d'un dallage avec leds au sol",
      "hide": true,
      "media": "dallage-15.jpg",
      "tags": [
        "création",
        "dallages"
      ]
    },
    {
      "head": "Terrasse en dalles",
      "hide": true,
      "media": "dallage-12.jpg",
      "tags": [
        "création",
        "dallages"
      ]
    },
    {
      "head": "Détails du dallage 60 x 30 cm",
      "hide": true,
      "media": "dallage-11.jpg",
      "tags": [
        "création",
        "dallages"
      ]
    },
    {
      "head": "Pavage naturel avec joints",
      "hide": true,
      "media": "pavage-naturel-01.jpg",
      "tags": [
        "création",
        "pavage"
      ]
    },
    {
      "head": "Chemin en pavés",
      "hide": true,
      "media": "pavage-01.jpg",
      "tags": [
        "création",
        "pavage"
      ]
    }
  ],
  "revision": "2021-02-09 (Tue) 04:02:03",
  "sections": [
    {
      "hide": true,
      "name": "jumbotron"
    },
    {
      "hide": true,
      "name": "featured"
    },
    {
      "name": "aboutus"
    },
    {
      "hide": true,
      "name": "whyus"
    },
    {
      "hide": true,
      "name": "ourclients"
    },
    {
      "name": "services"
    },
    {
      "hide": false,
      "name": "call2action"
    },
    {
      "name": "portfolio"
    },
    {
      "hide": true,
      "name": "team"
    },
    {
      "name": "contact"
    }
  ],
  "services": [
    {
      "desc": "Taille des arbres fruitiers <br>Traitements d'hiver <br>Taille d'arbres et arbustes <br>Élagages",
      "head": "Entretien des arbres",
      "icon": "<svg width=\"100\" height=\"100\" viewBox=\"0 0 600 600\" xmlns=\"http://www.w3.org/2000/svg\"><path stroke=\"none\"\nstroke-width=\"0\" fill=\"#66cc66\" d=\"M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,\n529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,\n328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,\n96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,\n40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,\n179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,\n421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,\n524.383925680826,300,521.0016835830174\"></path></svg>\n"
    },
    {
      "desc": "Tailles de haies",
      "head": "Entretien des haies",
      "icon": "<svg width=\"100\" height=\"100\" viewBox=\"0 0 600 600\" xmlns=\"http://www.w3.org/2000/svg\"><path stroke=\"none\"\nstroke-width=\"0\" fill=\"#66cc66\" d=\"M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,\n525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,\n314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,\n164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,\n18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,\n180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,\n384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,\n577.5009287672846,300,582.0697525312426\"></path></svg>\n"
    },
    {
      "desc": "Entretien des rosiers <br>Plantations de plantes annuelles <br>Autres plantations",
      "head": "Entretien des plates-bande",
      "icon": "<svg width=\"100\" height=\"100\" viewBox=\"0 0 600 600\" xmlns=\"http://www.w3.org/2000/svg\"><path stroke=\"none\"\nstroke-width=\"0\" fill=\"#66cc66\" d=\"M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,\n548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,\n332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,\n105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,\n5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,\n156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,\n401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,\n538.201503339737,300,541.5067337569781\"></path></svg>'\n"
    },
    {
      "desc": "Scarification <br>Fumure <br>Traitement <br>Tontes avec machines performantes pour petites et grandes surfaces, ainsi que les talus",
      "head": "Entretien du gazon",
      "icon": "<svg width=\"100\" height=\"100\" viewBox=\"0 0 600 600\" xmlns=\"http://www.w3.org/2000/svg\"><path stroke=\"none\"\nstroke-width=\"0\" fill=\"#66cc66\" d=\"M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,\n527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,\n327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,\n138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,\n8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,\n170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,\n396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,\n500.4755392045468,300,503.46388370962813\"></path></svg>'\n"
    },
    {
      "desc": "Balayage des feuilles mortes <br>Nettoyage complet des jardins",
      "head": "Nettoyage automnal",
      "icon": "<svg width=\"100\" height=\"100\" viewBox=\"0 0 600 600\" xmlns=\"http://www.w3.org/2000/svg\"><path stroke=\"none\"\nstroke-width=\"0\" fill=\"#66cc66\" d=\"M300,532.3542879108572C369.38199826031484,532.3153073249985,429.10787420159085,\n491.63046689027357,474.5244479745417,439.17860296908856C522.8885846962883,383.3225815378663,569.1668002868075,\n314.3205725914397,550.7432151929288,242.7694973846089C532.6665558377875,172.5657663291529,456.2379748765914,\n142.6223662098291,390.3689995646985,112.34683881706744C326.66090330228417,83.06452184765237,258.84405631176094,\n53.51806209861945,193.32584062364296,78.48882559362697C121.61183558270385,105.82097193414197,62.805066853699245,\n167.19869350419734,48.57481801355237,242.6138429142374C34.843463184063346,315.3850353017275,76.69343916112496,\n383.4422959591041,125.22947124332185,439.3748458443577C170.7312796277747,491.8107796887764,230.57421082200815,\n532.3932930995766,300,532.3542879108572\"></path></svg>\n"
    },
    {
      "desc": "Dallage et pavage <br>Construction de murs et escaliers <br>Pose de palissades en bois <br>Cabanes et pergolas <br>Autres sujets et articles en bois <br>Réalisation de nouveaux gazons <br>Plantations d'arbres et d'arbustes",
      "head": "Créations",
      "icon": "<svg width=\"100\" height=\"100\" viewBox=\"0 0 600 600\" xmlns=\"http://www.w3.org/2000/svg\"><path stroke=\"none\"\nstroke-width=\"0\" fill=\"#66cc66\" d=\"M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,\n552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,\n322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,\n127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,\n84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,\n160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,\n379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,\n558.5317968840102,300,566.797414625762\"></path></svg>\n"
    }
  ]
}
