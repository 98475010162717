import { mksrcset } from './srcset';

export function mkpicture (filename, extensions, label, classes, attributes) {

    let srcset = ''; for (let i = 0; i < extensions.length; ++ i)
    srcset +=  mksrcset ('source', filename, extensions[i], label, classes, attributes)
    srcset +=  mksrcset ('img', filename, filename .split('.').pop(), label, classes, attributes)

    return '<picture>' + srcset + '</picture>';

}
